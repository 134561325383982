import { FC, memo, Suspense } from 'react'
import { ToastContainer } from 'react-toastify'

import { I18NProvider } from '@ayub-begimkulov/i18n'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from 'config/queryClient'
import { AppLayout } from 'packages/app/layout/AppLayout'
import { TanStackRouterDevtools } from 'packages/app/routing'
import { ApplicationConfig } from 'packages/core/config'
import 'packages/app/vars.css'

interface Props {
  applicationConfig: ApplicationConfig
}

export const RootLayout: FC<Props> = memo(({ applicationConfig }) => (
  <I18NProvider i18n={applicationConfig.localization.i18n}>
    <QueryClientProvider client={queryClient}>
      <AppLayout applicationConfig={applicationConfig} />
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </QueryClientProvider>
  </I18NProvider>
))
